import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			planId: '',
			planName: '',
			accountType: '',
			account: '',
			type: '',
			userType: '',
			channels: '',
			redirectToUserManagement: false,
			planList: [],
			sendDate: '',
			expiryDate: '',
			moduleList: '',
			parentModuleList: '',
			moduleRows: [],
			allocatedmoduleList: [],
			moduleId: '',
			margin: '',
			interval: '',
			threshold: '',
			tier : '',
			isSub : false,
			cutting : false,
			userSubConfig : '',
			userSubConfigObj : {},
			isSubValue:false
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + parent)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
			
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					planId: res.planId,
					planName: res.planName,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					channels: res.channels,
					expiryDate: res.expiryDate,
					parentModuleList: res.modules,
					cutting : res.cutting,
					userSubConfig : res.userSubConfig,
					isSub : res.isSub
				})
				console.log("Cutting : "+this.state.cutting);
				if(this.state.isSub === "1"){
						this.state.isSubValue = true;
					}
				this.state.userSubConfigObj = this.state.userSubConfig;
				if(this.state.cutting){
					this.state.margin = this.state.userSubConfigObj.margin;
					this.state.interval = this.state.userSubConfigObj.interval;
					this.state.tier = this.state.userSubConfigObj.tier;
					this.state.threshold = this.state.userSubConfigObj.threshold;
					//this.state.isSub = this.state.userSubConfigObj.isSub;
					// if(this.state.userSubConfigObj.isSub === "1"){
					// 	this.state.isSubValue = true;
					// }
				}
				console.log("User Config : "+this.state.userSubConfigObj);
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

			Fetcher.get(apiPath + '/api/obd/user/profile/' + parent)
			.then(res => {
				this.setState({
					parentModuleList: res.modules
				})
			})
			.catch(err => { console.log('Error in fetching Parent data', err) });

		// Get User Module List
		Fetcher.get(apiPath + '/api/obd/module/list/' + userId)
		.then(res => {
			this.setState({
				moduleRows: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });

		// Get Module List
		Fetcher.get(apiPath + '/api/obd/module')
		.then(res => {
			this.setState({
				allocatedmoduleList: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	sendLaterDateChange = date => {
		this.setState({
			sendDate: date,
			expiryDate: date
		});
	};
	handleAddModuleRow = () => {
		if (this.state.moduleId !== '') {
			let moduleName = this.state.moduleId.substring(this.state.moduleId.lastIndexOf(',')+1,this.state.moduleId.length);
			let moduleId = this.state.moduleId.substring(0,this.state.moduleId.lastIndexOf(','));
			const item = {
				moduleId: moduleId,
				moduleName: moduleName
			};
			if(!this.state.moduleRows.length)
			{
				this.state.moduleRows = [];
			}
			const checkModule = obj => obj.moduleName === moduleName;
			if(this.state.moduleRows.some(checkModule))
			{
					Notify.notify('This module is already selected');
					return;
			}
			//const checkGroup = obj => obj.groupId === groupId;
			/*if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}*/
			
			this.state.moduleRows.push(item);
			this.setState({
				moduleRows: this.state.moduleRows
			});
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		else {
			Notify.notify("Please select Module");
		}
	};
	handleRemoveModuleRow = () => {
		this.setState({
			moduleRows: this.state.moduleRows.slice(0, -1)
		});
	};
	handleChangeModuleRow = idx => e => {
		const { name, value } = e.target;
		const moduleRows = [...this.state.moduleRows];
		moduleRows[idx] = {
			[name]: value
		};
		this.setState({
			moduleRows
		});
	};

	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		let date = '';
		let sendDateTime='';
		
		/*if(state.moduleRows.length == 0)
		{
			this.state.moduleList = {
				"moduleList": ""
			}
			//Notify.notify('Please select atleast one module');
			//return;
		}*/
		if(state.moduleList !== '' || state.moduleRows.length)
		{
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		else
		{
			this.state.moduleList = {
				"moduleList": []
			}
		}
		if (state.sendDate == null) {
			Notify.notify('Please Select Expiry Date');
			return;
		}
		if(state.sendDate === '')
		{
			sendDateTime = state.expiryDate;
		}
		else
		{
			date = state.sendDate;
			
			sendDateTime = this.addZero(date.getFullYear());
			sendDateTime += "-";
			sendDateTime += this.addZero((date.getMonth() + 1));
			sendDateTime += "-"
			sendDateTime += this.addZero(date.getDate());
			sendDateTime += " 23:59:59"
			/*sendDateTime += this.addZero(date.getHours());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getMinutes());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getSeconds());*/
		}

		if(this.state.cutting){
            
			let sValue = 0;
			if(this.state.isSubValue){
				sValue = 1;
			}
			const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			isSub : sValue,
			userSubConfig :{
				margin : this.state.margin,
				interval: this.state.interval,
				threshold : this.state.threshold,
				tier: this.state.tier
			},
			isCutting : true
		}
		this.saveData(data, false);

		}
        else{
			let sValue = 0;
			if(this.state.isSubValue){
				sValue = 1;
			}
		const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			isSub : sValue,
			isCutting : false
		}
		this.saveData(data, false);
	  }

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					redirectToUserManagement = false;
				} else {
					redirectToUserManagement = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Account', err)
				Notify.notify('Error in Updating Account');
			});

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
    
	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-list/'} />
		}
		const allocatedmoduleList = cloneDeep(this.state.allocatedmoduleList);
		let moduleDropdown = '';
		if (!isEmpty(allocatedmoduleList) && (!('error' in allocatedmoduleList))) {
			moduleDropdown = Array.isArray(allocatedmoduleList) ? allocatedmoduleList.map((obj, index) => {
				return <option value={obj.moduleId+','+obj.moduleName} key={`location${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			moduleDropdown = <option value="" >No module found</option>
		}

		const parentModuleList = cloneDeep(this.state.parentModuleList);
		let parentModuleDropdown = '';
		if (!isEmpty(parentModuleList) && (!('error' in parentModuleList))) {
			parentModuleDropdown = Array.isArray(parentModuleList) ? parentModuleList.map((obj, index) => {
				return <option value={obj.moduleId+','+obj.moduleName} key={`location${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			parentModuleDropdown = <option value="" >No module found</option>
		}

		const moduleRows = cloneDeep(this.state.moduleRows);
		const allocatedModulesTable = Array.isArray(moduleRows) ? moduleRows.map((item, index) => {
			return <tr key={index}>
				<td>{moduleRows[index].moduleName}</td>
				<td>
					<i onClick={() => this.handleRemoveModuleRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];
		let sendLaterDatepicker='';
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
		sendLaterDatepicker =
				<Col sm={3}>
					<Form.Group className="form-group required">
						<Form.Label>User Expiry</Form.Label>
							<DatePicker
								className="form-control"
								selected={this.state.expiryDate ? new Date(this.state.expiryDate) : this.state.sendDate}
								timeCaption="Time"
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								onChange={this.sendLaterDateChange}
								filterTime={filterTime}
							/>
					</Form.Group>
				</Col>


		const planList = cloneDeep(this.state.planList);
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option>No Plan</option>
		}
			
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Name</Form.Label>
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Email ID</Form.Label>
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Address</Form.Label>
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue( e )} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Pincode</Form.Label>
											<Form.Control required name="pincode" type="text" placeholder="Pincode" onChange={e => this.setvalue( e )} value={this.state.pincode} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Company</Form.Label>
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Plan</Form.Label>
											<Form.Select required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.planId}>{this.state.planName}</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
										<Form.Label>Update User Type</Form.Label>
											<Form.Select required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.userType}>{this.state.type}</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Account Type</Form.Label>
											<Form.Select required name="accountType" onChange={e => this.setvalue(e)} as="select">
												<option value="{this.state.accountType}">{this.state.account}</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Channels</Form.Label>
											<Form.Control required name="channels" type="text" value={this.state.channels} placeholder="No. of Channels" onChange={e => this.setvalue( e )}  />
										</Form.Group>
									</Col>
									{sendLaterDatepicker}
								</Row>
								<Row>
								{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className='form-group'>
											<Form.Select name="moduleId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Module</option>
												{moduleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={3}>
										<Form.Group className='form-group'>
											<Form.Select name="moduleId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Module</option>
												{parentModuleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={1}>
										<i onClick={this.handleAddModuleRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Module</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedModulesTable}
												</tbody>
											</table>
										</div>
									</Col>
									<Col sm={1}>
										
									</Col>
								</Row>

								<Row>
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.isSubValue} onChange={( e ) => this.toggleChange( e, 'isSubValue' )} custom inline name="isSubValue" label="Submission" id="isSubValue" type="checkbox" />
										     {console.log(this.state.isSubValue)}
										</Form.Group>
										</Col>}

									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.cutting} onChange={( e ) => this.toggleChange( e, 'cutting' )} custom inline name="cutting" label="Cutting" id="cutting" type="checkbox" />
										</Form.Group>
									</Col>}
								</Row>

								<Row>
									
									{(this.state.cutting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Margin</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select name="margin" onChange={e => this.setvalue(e)} as="select">
												{(!this.state.margin) ?<option value="">Select Margin</option> :
												 <option value={this.state.margin}>{this.state.margin}0%</option>
												}
												{/* <option value={this.state.margin}>{this.state.margin}0%</option> */}
												<option value="1">10%</option>
												<option value="2">20%</option>
												<option value="3">30%</option>
												<option value="4">40%</option>
												<option value="5">50%</option>
												<option value="6">60%</option>
												<option value="7">70%</option>
												<option value="8">80%</option>
												<option value="9">90%</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.state.cutting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Interval</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select required name="interval" value={this.state.interval} onChange={e => this.setvalue(e)} as="select">
												{(!this.state.interval) ?<option value="">Select Interval</option> :
												 <option value={this.state.interval}>{this.state.interval} sec</option>
												}
												<option value={this.state.interval}>{this.state.interval} sec</option>
												<option value="1">1 sec</option>
												<option value="2">2 sec</option>
												<option value="3">3 sec</option>
												<option value="4">4 sec</option>
												<option value="5">5 sec</option>
												<option value="6">6 sec</option>
												<option value="7">7 sec</option>
												<option value="8">8 sec</option>
												<option value="9">9 sec</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.state.cutting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Threshold</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="threshold" type="number" placeholder="Threshold" onChange={e => this.setvalue(e)} value={this.state.threshold} />
										</Form.Group>
									</Col>}
								</Row>

								<Row>
									{ (this.state.cutting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={6}>
										<Form.Label>Tier</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select required name="tier" value={this.state.tier} onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.tier}>Tier {this.state.tier}</option>
												<option value="1">Tier 1</option>
												<option value="2">Tier 2</option>
											</Form.Select>
										</Form.Group>
									</Col>}
								</Row>
								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUser;